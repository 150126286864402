// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$strategy-street-primary: mat-palette($mat-indigo);
$strategy-street-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$strategy-street-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$strategy-street-theme: mat-light-theme(
    (
        color: (
            primary: $strategy-street-primary,
            accent: $strategy-street-accent,
            warn: $strategy-street-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($strategy-street-theme);

/* You can add global styles to this file, and also import other style files */
@import "./layout.scss";
@import "./theme.scss";

* html,
body {
    height: 100%;
    margin: 0;
    font-family: "Poppins", sans-serif !important;
    overflow: hidden;
    line-height: 1.7;
}

// *::-webkit-scrollbar {
//     width: 0px;
//     height: 0px;
//     background-color: #fff;
// }
*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #fff;
}
*::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
}

a {
    text-decoration: none;
}
button,
textarea {
    font-family: "Poppins", sans-serif !important;
}

// font-size
.font-10 {
    font-size: 10px;
}
.font-11 {
    font-size: 11px;
}
.font-12 {
    font-size: 12px !important;
}
.font-13 {
    font-size: 13px;
}
.font-14 {
    font-size: 14px;
}
.font-16 {
    font-size: 16px;
}
.font-18 {
    font-size: 18px;
}
.font-20 {
    font-size: 20px;
}
.font-22 {
    font-size: 22px;
}
.font-24 {
    font-size: 24px;
}
// font-weight
.fw-300 {
    font-weight: 300;
}
.fw-400 {
    font-weight: 400;
}
.fw-500 {
    font-weight: 500 !important;
}
.fw-600 {
    font-weight: 600;
}
.fw-700 {
    font-weight: 700;
}
.fw-800 {
    font-weight: 800;
}
.flex1 {
    flex: 1;
}
.auth-outer {
    background-color: #f6faff;
}

.auth-bg {
    background-image: url("/assets/images/bg.svg");
    height: 92vh;
    height: 92vh;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 40px;
    background-position: center;
}

.flex-col {
    display: flex;
    flex-direction: column !important;
}
.radius-10 {
    border-radius: 10px;
}
.light-gray {
    color: #9b9fb7;
}
.grey {
    color: #bebebe;
}
.darkBlack {
    color: #363738;
}
.darkGray {
    color: #5e666c;
}

.status {
    padding: 3px 10px;
    font-size: 10px;
    border-radius: 30px;
    display: inline-table;
    margin: auto;
    &.blue {
        background-color: #e5f3ff;
        color: #008cff;
    }
    &.gray {
        background-color: #e7eff5;
        color: #9b9fb7;
    }
}

// theme-btn

.theme-btn {
    box-shadow: 0px 1px 19px #3178cc2b;
    border-radius: 26px;
    border: none;
    outline: none;
    font-size: 13px;
    font-weight: 600;
    position: relative;
    color: #fff;
    text-align: center;
    padding: 7px 0px;
    min-width: 120px;
    border: 1px solid transparent;
    cursor: pointer;

    &.blue {
        background-color: #008cff;
    }
    &.black {
        background-color: $black;
    }
    &.darkBlack {
        background-color: #363738;
    }
    &.red-btn {
        background-color: #ff4343;
    }
    &.white {
        background-color: #fff;
        color: #363738 !important;
    }
    &.yellow {
        background-color: $yellow;
    }
    &.dark-blue {
        background-color: $darkBlue;
    }
    &.sm {
        padding: 5px 10px !important;
        font-size: 11px !important;
        font-weight: 400 !important;
    }
    &.clear-filter {
        min-width: 89px !important;
        height: 27px !important;
    }
    &.outline {
        background-color: transparent;
        &.darkBlack {
            border-color: #363738;
            color: #363738;
        }
    }
    &.icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .mat-progress-spinner {
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
}

.lightloader {
    .loading.mat-progress-spinner circle,
    .mat-spinner circle {
        stroke: #008cff;
    }
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #fff;
}

.form {
    width: 38%;
}

.smallNav {
    .farell {
        display: none;
        align-items: center;
    }
    .farell-outer {
        padding-left: 0px !important;
        padding-right: 0px !important;
        justify-content: center;
    }
    .sidebar-bg {
        .logo-img {
            display: none;
        }
        width: 100px !important;
        transition: 1s;
        transition-timing-function: ease-in-out;
        li {
            padding: 12px 0px !important;
            justify-content: center;
            .nav-item {
                display: none;
            }
        }
    }
}

.profileDialog.cdk-overlay-pane {
    position: absolute !important;
    right: 19px;
    top: 21px;

    .mat-dialog-container {
        border-radius: 10px !important;
    }
}

.notification-dialog.cdk-overlay-pane {
    position: absolute !important;
    right: 66px;
    top: 47px;

    .mat-dialog-container {
        border-radius: 10px !important;
    }
}

.changePassword {
    .mat-dialog-container {
        border-radius: 10px !important;
        background-color: #f6faff;
        padding: 30px !important;
    }
}

.bg-color {
    .mat-dialog-container {
        border-radius: 10px !important;
        background-color: #f6faff;
    }
}
.border-radius {
    .mat-dialog-container {
        border-radius: 10px !important;
    }
}
.nowrap {
    white-space: nowrap;
}

.user-img {
    border-radius: 50%;
    &.lImg {
        width: 148px;
        height: 148px;
    }
    &.sImg {
        width: 26px;
        height: 26px;
    }
}
.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.09) !important;
}

// page ui
.page-container {
    display: flex;
    flex-direction: column;
    height: calc(100% - 80px);
    padding: 0px 24px;
    // overflow-y: scroll;
    .page-header {
        display: flex;
        align-items: center;
        .search {
            position: relative;
            input {
                background-color: $black;
                border-radius: 13px;
                color: #bebebe;
                width: 340px;
                height: 26px;
                padding: 5px 33px 5px 12px;
                font-size: 10px;
                outline: none;
                border: none;
                &::placeholder {
                    color: #bebebe;
                    font-family: "Poppins", sans-serif !important;
                }
            }
            .search-icon {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                right: 10px;
            }
        }
    }
}

.filter-dropdown {
    display: flex;
    align-items: center;
    background-color: #e7eff5;
    height: 28px;
    border-radius: 30px;
    padding: 1px 6px 0px 10px;
    margin: 0px 10px;
    font-size: 12px;
    &.width {
        width: 110px;
    }
    img {
        margin-right: 10px;
    }
}

.page-content {
    flex: 1;
    overflow-y: auto;
}
.pointer {
    cursor: pointer;
}
.min-h-100 {
    min-height: 100%;
}
.w-90 {
    width: 90%;
}

.box {
    box-shadow: 0px 2px 10px #0000000d;
    border-radius: 10px;
    word-break: break-word;
    padding: 15px;
    &.grey {
        background-color: #f1f1f1;
        width: 357px;
        height: 66px;
    }
    &.white {
        background-color: white;
    }
    &.black {
        background-color: $black;
    }
    &.sm {
        border-radius: 10px !important;
    }
    .profile-info {
        display: flex;
        align-items: center;
        .user-img {
            height: 78px;
            width: 78px;
            border-radius: 50%;
        }
    }
}

.dropdown {
    .mat-menu-content {
        padding: 8px 8px !important;
    }
    .mat-menu-item {
        height: 33px;
        line-height: 0px;
        border-bottom: 1px solid #f1f1f1;
        padding: 0px 4px !important;
        &:last-child {
            border: 0px !important;
        }
    }
}

.status-btn {
    font-size: 11px;
    background-color: #e7eff5;
    color: #9b9fb7;
    border-radius: 14px;
    padding: 3px 6px;
    max-width: 110px;
    min-width: 60px;
    text-align: center;
    &.white {
        background-color: #fff !important;
        color: #9b9fb7 !important;
        border: 1px solid #e7eff5;
        min-width: 73px;
    }
}

.border {
    border-bottom: 2px solid #bebebe;
}

.break-word {
    word-break: break-word;
}

.table {
    border-collapse: collapse;
    tr {
        border-bottom: 1px solid #f1f1f1;
        &:last-child {
            border-bottom: 0px;
        }
        th {
            color: #9b9fb7;
            font-weight: 400;
            font-size: 12px;
            text-align: left;
            padding: 12px 10px;
        }
        td {
            font-size: 12px;
            padding: 12px 10px;
            word-break: break-word;
        }
    }
}

textarea {
    height: 100px;
    &.input-control {
        color: #000;
        outline: none;
        border: none;
        font-size: 14px;
        font-weight: 500;
        width: 100%;
        background-color: #fff;
        border-radius: 16px;
        padding: 0px 6px;
    }
    &.ng-touched.ng-invalid {
        + .errors {
            display: initial;
        }
    }
}
.red-border {
    border: 1px solid red;
}

.form-field {
    .error {
        color: red;
        display: none;
        position: absolute;
        top: 72px;
        left: 0px;
        font-size: 12px;
    }

    .inpt-control {
        color: #000;
        outline: none;
        border: 1px solid transparent;
        font-size: 14px;
        font-weight: 500;
        width: 100%;
        font-family: "Poppins", sans-serif !important;
        background-color: #fff;
        box-shadow: 0px 1px 19px #194a841c;
        border-radius: 16px;
        padding: 25px 15px 25px 47px;
        &.icon-none {
            padding: 36px 15px 14px 20px !important;
        }
    }
    .icon {
        position: absolute;
        left: 20px;
        top: 29px;
    }
    .eyeicon {
        position: absolute;
        right: 16px !important;
        top: 29px;
        width: 16px;
    }

    input.ng-invalid.ng-touched {
        border: 1px solid red;
        + .error {
            display: initial;
            color: red;
        }
    }

    input {
        &:focus ~ label {
            font-size: 10px;
            top: 15px;
            left: 20px;
            color: #bebebe !important;
            + .icon {
                position: absolute;
                left: 20px;
                top: 38px;
            }
        }
        & ~ label {
            position: absolute;
            left: 48px;
            top: 25px;
            transition: 0.2s all;
            cursor: text;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            color: black;
        }
        &.icon-none {
            & ~ label {
                left: 20px;
            }
        }
        &.icon-none.ng-valid {
            & ~ label {
                font-size: 10px;
                top: 15px;
                color: #bebebe !important;
            }
        }

        &:focus {
            padding: 36px 15px 14px 47px;
            &:valid ~ label {
                font-size: 10px;
                top: 15px;
                left: 20px;
                color: #bebebe;
            }
        }

        &:valid {
            padding: 36px 15px 14px 47px;
        }
        &:valid ~ label {
            font-size: 10px;
            top: 15px;
            left: 20px;
            color: #bebebe;

            + .icon {
                position: absolute;
                left: 20px;
                top: 38px;
            }
        }

        & ~ .focus {
            display: none;
        }
    }
    .mat-progress-spinner circle,
    .mat-spinner circle {
        stroke: #008cff;
    }
    .loader {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 14px;
    }
}

.theme-autocomplete {
    .mat-option {
        line-height: 36px;
        height: 36px;
    }
}

.group-logo {
    width: 49px;
    height: 49px;
    border-radius: 10px;
}

// active non-active
.non-active {
    font-size: 16px;
    font-weight: 600;
    color: #9b9fb7;
    &:hover {
        color: #008cff !important;
        padding-bottom: 5px;
        font-weight: 600;
        font-size: 16px;
    }
}

.detail-view {
    .link-active {
        color: #008cff !important;
        padding-bottom: 8px;
        font-weight: 600;
        font-size: 16px;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            border-bottom-style: solid;
            border-bottom-color: #008cff;
            border-bottom-width: 5px;
            width: 41px;
            margin: auto;
            border-radius: 2px;
            bottom: 0px;
            left: 0;
            right: 0;
        }
    }
}

// textarea css
textarea {
    resize: none;
}

textarea {
    &:focus ~ label {
        font-size: 10px;
        top: -23px;
        left: 10px !important;
        color: #bebebe !important;
        + .icon {
            position: absolute;
            left: 20px;
            top: 38px;
        }
    }

    & ~ label {
        position: absolute;
        left: 10px;
        top: -10px;
        transition: 0.2s all;
        cursor: text;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: black;
    }

    &:focus {
        padding: 0px 15px 14px 5px;
        &:valid ~ label {
            font-size: 10px;
            top: -23px;
            left: 10px !important;
        }
    }

    &:valid {
        padding: 0px 15px 14px 10px;
    }
    &:valid ~ label {
        font-size: 10px;
        top: -23px;
        left: 10px !important;
        color: #bebebe;

        + .icon {
            position: absolute;
            left: 20px;
            top: 38px;
        }
    }
    & ~ .focus {
        display: none;
    }
}

.textarea-outer {
    padding: 15px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 1px 19px #194a841c;
}

.arrow {
    .mat-select-arrow {
        border-left: 4px solid transparent !important;
        border-right: 4px solid transparent !important;
        border-top: 4px solid !important;
    }
}

.timepicker-dial__control {
    outline: none !important;
}

.red {
    color: #ff4343;
}

.underline {
    text-decoration: underline;
}

.visibility-hidden {
    visibility: hidden;
}
.position-absolute {
    position: absolute;
}
.mat-date-range-input-separator {
    opacity: 1 !important;
    color: rgb(179 179 179 / 87%);
}

.line-through {
    text-decoration: line-through;
}
